define("itarp-template-designer/utils/template-save", ["exports", "itarp-template-designer/config/environment", "@ember/runloop"], function (_exports, _environment, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.handleTemplateSave = handleTemplateSave;

  function handleTemplateSave(controller) {
    if (!validTemplate(controller)) return;
    controller.template.save().then(() => handleSaveSuccess(controller)).catch(errors => console.log(errors));
  }

  function validTemplate(controller) {
    if (controller.template.categories.length === 0) {
      controller.error = true;
      (0, _runloop.next)(controller, function () {
        scrollToError();
      });
      return false;
    }

    controller.error = false;
    return true;
  }

  function scrollToError() {
    const alignToTop = false;
    document.querySelector('.has-error').scrollIntoView(alignToTop);
  }

  function handleSaveSuccess(controller) {
    const willPublish = controller.template.isPublished;
    const transition = controller.appRouter.transitionTo(`${_environment.default.modulePrefix}`);
    transition.finally(() => {
      if (willPublish) {
        controller.toast.show('The publishing process has been started, and you will receive a notification once it is finished.', {
          autoHide: false,
          size: 'lg'
        });
      }
    });
  }
});