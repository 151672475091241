define("itarp-template-designer/serializers/category", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CategorySerializer extends _jsonApi.default {
    normalize(modelClass, resourceHash) {
      const result = super.normalize(modelClass, resourceHash);

      if (resourceHash.meta && result.data.attributes) {
        var _resourceHash$meta;

        result.data.attributes.meta = (_resourceHash$meta = resourceHash.meta) === null || _resourceHash$meta === void 0 ? void 0 : _resourceHash$meta.templates_meta;
      }

      return result;
    }

  }

  _exports.default = CategorySerializer;
});